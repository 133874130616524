import axios from "axios";
import { eventBus } from "@/main";

const moduleReport = {
  namespaced: true,
  state: {
    reportDateRange: []
  },
  mutations: {
    setReportDateRange(state, data) {
      state.reportDateRange = data ? data : [];
    }
  },
  actions: {
    fetchReportBranchDoctorIncome({ commit }, payload) {
      return axios
        .get(
          `/api/report/branchDoctorIncome/${payload.clinicUrl}/${payload.branchUrl}`,
          {
            params: {
              startDt: payload.dateFrom,
              endDt: payload.dateTo
            }
          }
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    fetchReportBranchReceiptItem({ commit }, payload) {
      return axios
        .get(
          `/api/report/receiptItem/${payload.clinicUrl}/${payload.branchUrl}`,
          {
            params: {
              startDt: payload.startDt,
              endDt: payload.endDt
            }
          }
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    fetchReportBranchReceipt({ commit }, payload) {
      return axios
        .get(
          `/api/report/branchReceipt/${payload.clinicUrl}/${payload.branchUrl}`,
          {
            params: {
              startDt: payload.dateFrom,
              endDt: payload.dateTo,
              receiptStatus: payload.receiptStatus
            }
          }
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    async emailReportBranchReceipt({ commit }, payload) {
      return axios
        .get(
          `/api/v2/report/branchReceipt/${payload.clinicUrl}/${payload.branchUrl}`,
          {
            params: {
              startDt: payload.dateFrom,
              endDt: payload.dateTo,
              receiptStatus: payload.receiptStatus
            }
          }
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    fetchReportDoctorReceiptItem({ commit }, payload) {
      return axios
        .get(
          `/api/report/receiptItem/${payload.clinicUrl}/${payload.branchUrl}`,
          {
            params: {
              startDt: payload.dateFrom,
              endDt: payload.dateTo,
              actionUid: payload.actionUid
            }
          }
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    emailReportPaymentMethod({ commit }, payload) {
      return axios
        .get(
          `/api/v2/report/receiptPayment/${payload.clinicUrl}/${payload.branchUrl}`,
          {
            params: {
              startDt: payload.dateFrom,
              endDt: payload.dateTo,
              groupBy: payload.groupBy || "day"
            }
          }
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchReportPaymentMethod({ commit }, payload) {
      return axios
        .get(
          `/api/report/receiptPayment/${payload.clinicUrl}/${payload.branchUrl}`,
          {
            params: {
              startDt: payload.dateFrom,
              endDt: payload.dateTo,
              groupBy: payload.groupBy || "day"
            }
          }
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchOverdueItemsReport({ commit }, payload) {
      return axios
        .get(
          `/api/report/branchReceiptItemOverdue/${payload.clinicUrl}/${payload.branchUrl}`,
          {
            params: {
              startDt: payload.dateFrom,
              endDt: payload.dateTo
            }
          }
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchPatientPaymentItem({ commit }, payload) {
      let url = `/api/report/patientReceiptItem/${payload.clinicUrl}/${payload.branchUrl}/${payload.patientID}`;
      return axios
        .get(url)
        .then(res => {
          return res.data.report;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchPatientPaymentReceipt({ commit }, payload) {
      let url = `/api/report/patientReceipt/${payload.clinicUrl}/${payload.branchUrl}/${payload.patientID}`;
      return axios
        .get(url)
        .then(res => {
          return res.data.report;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    //--------- State ---------//
    setStateReportDateRange({ commit }, data) {
      commit("setReportDateRange", data);
    }
  },
  getters: {
    getReportDateRange: state => {
      return state.reportDateRange;
    }
  }
};
export default moduleReport;

import axios from "axios";
import moment from "moment";
import { get, isEmpty } from "lodash";
import { eventBus } from "@/main";

const moduleAppointment = {
  namespaced: true,
  state: {
    //--------- Master ---------//
    treatmentList: [],
    treatmentCateList: [],

    treatDiagList: [],
    treatDiagCateList: [],

    treatList: [],
    treatCateList: [],

    itemList: [],
    itemCateList: [],

    //--------- Queue ---------//
    queueDate: null, //'YYYY-MM-DD'
    appointment: null,
    queueAppointmentList: [],
    tomorrowAppointmentList: [],
    appointmentHistoryList: [],

    //--------- Doctor ---------//
    doctorDate: null, //'YYYY-MM-DD'
    doctorAppointmentList: [],
    doctorCheckInList: [],

    //------ All doctors appointments  and check-in ------//
    allDoctorsDate: null,
    allDoctorsAppointment: [],
    allCheckInList: [],

    //---- waiting for payment ----//
    waitingForPaymentApptList: [],

    //---- waiting for payment ----//
    poatangApptList: []
  },
  mutations: {
    //--------- Master ---------//
    setTreatmentList(state, data) {
      state.treatmentList = data;
    },
    setTreatmentCateList(state, data) {
      state.treatmentCateList = data;
    },

    setTreatDiagList(state, data) {
      state.treatDiagList = data;
    },
    setTreatDiagCateList(state, data) {
      state.treatDiagCateList = data;
    },
    setTreatList(state, data) {
      state.treatList = data;
    },
    setTreatCateList(state, data) {
      state.treatCateList = data;
    },
    setItemList(state, data) {
      state.itemList = data;
    },
    setItemCateList(state, data) {
      state.itemCateList = data;
    },
    //--------- Queue ---------//
    setQueueDate(state, date) {
      state.queueDate = date;
    },
    setAppointment(state, data) {
      state.appointment = data ? data : null;
    },
    setQueueAppointmentList(state, data) {
      state.queueAppointmentList = data ? data : [];
    },
    setTomorrowAppointmentList(state, data) {
      state.tomorrowAppointmentList = data ? data : [];
    },
    setAppointmentHistoryList(state, data) {
      state.appointmentHistoryList = data ? data : [];
    },
    setWaitingForPaymentApptList(state, data) {
      state.waitingForPaymentApptList = data ? data : [];
    },
    setPoatangApptList(state, data) {
      state.poatangApptList = data ? data : [];
    },
    createQueueAppointment(state, data) {
      var index = state.queueAppointmentList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (moment(data.start).format("YYYY-MM-DD") === state.queueDate) {
        if (index < 0) {
          state.queueAppointmentList.push(data);
        } else {
          Object.assign(state.queueAppointmentList[index], data);
        }
      }
      let tmrIndex = state.tomorrowAppointmentList.findIndex(
        e => e.id == data.id
      );

      if (moment(data.start).isSame(moment().add(1, "day"), "day")) {
        if (tmrIndex < 0) {
          state.tomorrowAppointmentList.push(data);
        } else {
          Object.assign(state.tomorrowAppointmentList[tmrIndex], data);
        }
      }
      if (moment(data.start).format("YYYY-MM-DD") === state.allDoctorsDate) {
        return;
      }
    },
    createWaitingForPaymentApptList(state, data) {
      var index = state.waitingForPaymentApptList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (moment(data.start).format("YYYY-MM-DD") === state.queueDate) {
        if (index < 0) {
          state.waitingForPaymentApptList.push(data);
        } else {
          Object.assign(state.waitingForPaymentApptList[index], data);
        }
      }
    },
    deleteWaitingForPaymentApptList(state, id) {
      var index = state.waitingForPaymentApptList.findIndex(function(event) {
        return event.id == id;
      });
      if (index >= 0) {
        state.waitingForPaymentApptList.splice(index, 1);
      }
    },
    updateQueueAppointment(state, data) {
      var index = state.queueAppointmentList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        Object.assign(state.queueAppointmentList[index], data);

        let poatangList = state.queueAppointmentList.filter(i =>
          [11, 12].includes(i.extendedProps.statusId)
        );
        state.poatangApptList = poatangList ? poatangList : [];
      }
      if (
        moment(data.start).format("YYYY-MM-DD") === state.queueDate &&
        index === -1
      ) {
        state.queueAppointmentList.push(data);
      }

      let tmrIndex = state.tomorrowAppointmentList.findIndex(
        e => e.id == data.id
      );
      if (moment(data.start).isSame(moment().add(1, "day"), "day")) {
        if (tmrIndex >= 0) {
          Object.assign(state.tomorrowAppointmentList[tmrIndex], data);
        }
        if (tmrIndex === -1) {
          state.tomorrowAppointmentList.push(data);
        }
      }
    },
    deleteQueueAppointment(state, id) {
      let index = state.queueAppointmentList.findIndex(function(event) {
        return event.id == id;
      });
      if (index >= 0) {
        state.queueAppointmentList.splice(index, 1);
      }

      let tmrIndex = state.tomorrowAppointmentList.findIndex(function(event) {
        return event.id == id;
      });
      if (tmrIndex >= 0) {
        state.tomorrowAppointmentList.splice(index, 1);
      }
    },
    //--------- Favorite --------//
    setFavoriteTreatmentList(state, data) {
      var index = state.treatmentList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        state.treatmentList[index].favorite = !data.favorite;
      }
    },
    setFevoriteTreatDiagList(state, data) {
      var index = state.treatDiagList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        state.treatDiagList[index].favorite = !data.favorite;
      }
    },
    setFevoriteTreatList(state, data) {
      var index = state.treatList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        state.treatList[index].favorite = !data.favorite;
      }
    },
    setFevoriteItemList(state, data) {
      var index = state.itemList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        state.itemList[index].favorite = !data.favorite;
      }
    },

    //--------- Doctor ---------//
    setDoctorDate(state, date) {
      state.doctorDate = date;
    },
    setDoctorAppointmentList(state, data) {
      state.doctorAppointmentList = data ? data : [];
    },
    createDoctorAppointment(state, data) {
      var index = state.doctorAppointmentList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (moment(data.start).format("YYYY-MM-DD") === state.doctorDate) {
        if (index < 0) {
          state.doctorAppointmentList.push(data);
        } else {
          Object.assign(state.doctorAppointmentList[index], data);
        }
      }
    },
    updateDoctorAppointment(state, data) {
      var index = state.doctorAppointmentList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        Object.assign(state.doctorAppointmentList[index], data);
      }
      if (
        moment(data.start).format("YYYY-MM-DD") === state.doctorDate &&
        index === -1
      ) {
        state.doctorAppointmentList.push(data);
      }
    },
    deleteDoctorAppointment(state, id) {
      var index = state.doctorAppointmentList.findIndex(function(event) {
        return event.id == id;
      });
      if (index >= 0) {
        state.doctorAppointmentList.splice(index, 1);
      }
    },
    setDoctorCheckInList(state, data) {
      state.doctorCheckInList = data ? data : [];
    },

    createDoctorCheckInList(state, data) {
      var index = state.doctorCheckInList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index < 0) {
        state.doctorCheckInList.unshift(data);
      } else {
        Object.assign(state.doctorCheckInList[index], data);
      }
    },
    updateDoctorCheckInList(state, data) {
      var index = state.doctorCheckInList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        Object.assign(state.doctorCheckInList[index], data);
      }
    },
    deleteDoctorCheckInList(state, id) {
      var index = state.doctorCheckInList.findIndex(function(event) {
        return event.id == id;
      });
      if (index >= 0) {
        state.doctorCheckInList.splice(index, 1);
      }
    },

    //---- All Doctors ----//

    setAllDoctorsDate(state, date) {
      state.allDoctorsDate = date;
    },
    setAllDoctorsAppointment(state, data) {
      state.allDoctorsAppointment = data ? data : [];
    },
    setAllCheckInList(state, data) {
      state.allCheckInList = data ? data : [];
    },
    createAllDoctorsAppointment(state, data) {
      let index = state.allDoctorsAppointment.findIndex(
        event => event.id == data.id
      );
      if (moment(data.start).format("YYYY-MM-DD") === state.allDoctorsDate) {
        if (index < 0) {
          state.allDoctorsAppointment.push(data);
        } else {
          Object.assign(state.allDoctorsAppointment[index], data);
        }
      }
    },
    updateAllDoctorsAppointment(state, data) {
      let index = state.allDoctorsAppointment.findIndex(
        event => event.id == data.id
      );
      if (index >= 0) {
        Object.assign(state.allDoctorsAppointment[index], data);
      }
    },
    deleteAllDoctorsAppointment(state, id) {
      let index = state.allDoctorsAppointment.findIndex(
        event => event.id == id
      );
      if (index >= 0) {
        state.allDoctorsAppointment.splice(index, 1);
      }
    },
    createAllDoctorsCheckInList(state, data) {
      state.allCheckInList.unshift(data);
    },
    updateAllDoctorsCheckInList(state, data) {
      let index = state.allCheckInList.findIndex(event => event.id == data.id);
      if (index >= 0) {
        Object.assign(state.allCheckInList[index], data);
      }
    },

    deleteAllDoctorsCheckInList(state, id) {
      let index = state.allCheckInList.findIndex(event => event.id == id);
      if (index >= 0) {
        state.allCheckInList.splice(index, 1);
      }
    },

    // list master
    createTreatmentList(state, data) {
      state.treatmentList.unshift(data);
    },
    updateTreatmentList(state, data) {
      var index = state.treatmentList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        Object.assign(state.treatmentList[index], data);
      }
    },
    deleteTreatmentList(state, data) {
      var index = state.treatmentList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        state.treatmentList.splice(index, 1);
      }
    },
    deleteTreatmentCateList(state, data) {
      var index = state.treatmentCateList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        state.treatmentCateList.splice(index, 1);
      }
    },

    createTreatList(state, data) {
      state.treatList.unshift(data);
    },
    updateTreatList(state, data) {
      var index = state.treatList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        Object.assign(state.treatList[index], data);
      }
    },
    deleteTreatList(state, data) {
      var index = state.treatList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        state.treatList.splice(index, 1);
      }
    },
    deleteTreatCateList(state, data) {
      var index = state.treatCateList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        state.treatCateList.splice(index, 1);
      }
    },

    createTreatDiagList(state, data) {
      state.treatDiagList.unshift(data);
    },
    updateTreatDiagList(state, data) {
      var index = state.treatDiagList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        Object.assign(state.treatDiagList[index], data);
      }
    },
    deleteTreatDiagList(state, data) {
      var index = state.treatDiagList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        state.treatDiagList.splice(index, 1);
      }
    },
    deleteTreatDiagCateList(state, data) {
      var index = state.treatDiagCateList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        state.treatDiagCateList.splice(index, 1);
      }
    },

    createItemList(state, data) {
      state.itemList.unshift(data);
    },
    updateItemList(state, data) {
      var index = state.itemList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        Object.assign(state.itemList[index], data);
      }
    },
    deleteItemList(state, data) {
      var index = state.itemList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        state.itemList.splice(index, 1);
      }
    },
    deleteItemCateList(state, data) {
      var index = state.itemCateList.findIndex(function(event) {
        return event.id == data.id;
      });
      if (index >= 0) {
        state.itemCateList.splice(index, 1);
      }
    }
  },
  actions: {
    //--------- Master API ---------//
    fetchTreatmentList({ commit, getters }, payload) {
      return (
        axios
          .get(
            `/api/master/apptTreat/${payload.clinicUrl}/${payload.branchUrl}`
          )
          // .get(`/api/master/apptTreat/${payload.clinicUrl}/${payload.branchUrl}`)
          .then(res => {
            commit("setTreatmentList", res.data);
            return res;
          })
          .catch(err => {
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },

    fetchTreatmentCateList({ commit, getters }, payload) {
      return axios
        .get(
          `/api/master/apptTreatCate/${payload.clinicUrl}/${payload.branchUrl}`
        )
        .then(res => {
          commit("setTreatmentCateList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    async fetchAppointmentCateAndList({ commit, getters, dispatch }, payload) {
      await dispatch("fetchTreatmentCateList", payload);
      await dispatch("fetchTreatmentList", payload);
    },

    fetchTreatDiagList(
      { commit, getters, rootGetters },
      { clinicUrl, branchUrl }
    ) {
      const branchInfo = rootGetters["moduleUser/getBranchInfo"];
      return (
        axios
          .get(`/api/master/treatDiag/${clinicUrl}/${branchUrl}`, {
            params: { includeICD10: branchInfo.linkedWithNhso }
          })
          // .get(`/api/master/treatDiag/${payload.clinicUrl}/${payload.branchUrl}`)
          .then(res => {
            commit("setTreatDiagList", res.data);
            return res;
          })
          .catch(err => {
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },

    fetchTreatDiagCateList({ commit, getters }, payload) {
      return axios
        .get(
          `/api/master/treatDiagCate/${payload.clinicUrl}/${payload.branchUrl}`
          // `/api/master/treatDiagCate/${payload.clinicUrl}/${payload.branchUrl}`
        )
        .then(res => {
          commit("setTreatDiagCateList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    async fetchDiagnosisCateAndList({ commit, getters, dispatch }, payload) {
      await dispatch("fetchTreatDiagCateList", payload);
      await dispatch("fetchTreatDiagList", payload);
    },

    fetchTreatList({ commit, getters, rootGetters }, payload) {
      const branchInfo = rootGetters["moduleUser/getBranchInfo"];
      return (
        axios
          .get(`/api/master/treat/${payload.clinicUrl}/${payload.branchUrl}`, {
            params: { includeICD10TM: branchInfo.linkedWithNhso }
          })
          // .get(`/api/master/treat/${payload.clinicUrl}/${payload.branchUrl}`)
          .then(res => {
            commit("setTreatList", res.data);
            return res;
          })
          .catch(err => {
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },

    fetchTreatCateList({ commit, getters }, payload) {
      return (
        axios
          .get(
            `/api/master/treatCate/${payload.clinicUrl}/${payload.branchUrl}`
          )
          // .get(`/api/master/treatCate/${payload.clinicUrl}/${payload.branchUrl}`)
          .then(res => {
            commit("setTreatCateList", res.data);
            return res;
          })
          .catch(err => {
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },
    async fetchTreatmentCateAndList({ commit, getters, dispatch }, payload) {
      await dispatch("fetchTreatCateList", payload);
      await dispatch("fetchTreatList", payload);
    },

    fetchItemList({ commit, getters }, payload) {
      return (
        axios
          .get(`/api/master/item/${payload.clinicUrl}/${payload.branchUrl}`)
          // .get(`/api/master/item/${payload.clinicUrl}/${payload.branchUrl}`)
          .then(res => {
            commit("setItemList", res.data);
            return res;
          })
          .catch(err => {
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },

    fetchItemCateList({ commit, getters }, payload) {
      return (
        axios
          .get(`/api/master/itemCate/${payload.clinicUrl}/${payload.branchUrl}`)
          // .get(`/api/master/itemCate/${payload.clinicUrl}/${payload.branchUrl}`)
          .then(res => {
            commit("setItemCateList", res.data);
            return res;
          })
          .catch(err => {
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },
    async fetchItemCateAndList({ commit, getters, dispatch }, payload) {
      await dispatch("fetchItemCateList", payload);
      await dispatch("fetchItemList", payload);
    },

    //--------- Queue API ---------//
    fetchQueueAppointmentList({ commit, getters }, payload) {
      if (!payload.store && payload.store !== false) {
        payload.store = true;
      }
      return axios
        .get(`/api/appointment/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: {
            startDt: moment(payload.date)
              .startOf("day")
              .format(),
            endDt: moment(payload.date)
              .endOf("day")
              .format()
          }
        })
        .then(res => {
          const appointmentList = res.data.appointments.map(event => {
            return getters.getAppointmentFormat(event);
          });
          if (payload.store) {
            commit("setQueueAppointmentList", appointmentList);

            let waitingForpaymentList = appointmentList.filter(
              i => i.extendedProps.statusId === 6
            );
            commit("setWaitingForPaymentApptList", waitingForpaymentList);

            let poatangList = appointmentList.filter(i =>
              [11, 12].includes(i.extendedProps.statusId)
            );
            commit("setPoatangApptList", poatangList);
          }
          res.data = appointmentList;
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchTomorrowAppointmentList({ commit, getters }, payload) {
      let tommorow = moment().add(1, "day");
      return axios
        .get(`/api/appointment/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: {
            startDt: tommorow.startOf("day").format(),
            endDt: tommorow.endOf("day").format()
          }
        })
        .then(res => {
          const appointmentList = res.data.appointments.map(event => {
            return getters.getAppointmentFormat(event);
          });
          commit("setTomorrowAppointmentList", appointmentList);
          res.data = appointmentList;
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchWaitingForPaymentApptList({ commit, getters }, payload) {
      if (!payload.store && payload.store !== false) {
        payload.store = true;
      }
      return axios
        .get(`/api/appointment/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: {
            startDt: moment(payload.date)
              .startOf("day")
              .format(),
            endDt: moment(payload.date)
              .endOf("day")
              .format(),
            statusId: 6
          }
        })
        .then(res => {
          const appointmentList = res.data.appointments.map(event => {
            return getters.getAppointmentFormat(event);
          });
          if (payload.store) {
            commit("setWaitingForPaymentApptList", appointmentList);
          }
          res.data = appointmentList;
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchQueueAppointmentById({ commit, getters }, payload) {
      return axios
        .get(
          `/api/appointment/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
        )
        .then(res => {
          const appointment = getters.getAppointmentFormat(res.data);
          commit("updateQueueAppointment", appointment);
          commit("setAppointment", appointment);
          res.data = appointment;
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchQueueAppointmentDraftByApptId({ commit, getters }, payload) {
      return axios
        .get(
          `/api/appointmentDraft/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
        )
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchAppointmentHistoryList({ commit, getters }, payload) {
      return axios
        .get(`/api/appointment/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: {
            patientId: payload.patientId
          }
        })
        .then(res => {
          commit("setAppointmentHistoryList", res.data.appointments);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    createQueueAppointment({ commit, getters }, payload) {
      return axios
        .post(
          `/api/appointment/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          const appointment = getters.getAppointmentFormat(res.data);
          commit("createQueueAppointment", appointment);
          res.data = appointment;
          return res;
        })
        .catch(err => {
          const statusCode = err.response?.data.errorCode || null;
          const errorMessage = err.response?.data.errorMessage || null;
          if (statusCode === 400 && errorMessage === "No data found")
            eventBus.$emit("alertSwal", {
              title:
                "ไม่สามารถส่งตรวจได้ เนื่องจากไม่พบข้อมูลการเข้ารับบริการใน KTB",
              message: `อาจเกิดจาก เลขบัตรประชาชนไม่ตรง หรือยังไม่ได้กดรับบริการใน KTB`,
              icon: "warning"
            });
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    createQueueAppointmentDraft({ commit, getters }, payload) {
      return axios
        .post(
          `/api/appointmentDraft/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateQueueAppointmentDraft({ commit, getters }, payload) {
      const id = payload.event.id;
      delete payload.event.id;
      return axios
        .put(
          `/api/appointmentDraft/${payload.clinicUrl}/${payload.branchUrl}/${id}`,
          payload.event
        )
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateQueueAppointment({ commit, getters }, payload) {
      return axios
        .put(
          `/api/appointment/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          payload.event
        )
        .then(res => {
          const appointment = getters.getAppointmentFormat(res.data);
          commit("updateQueueAppointment", appointment);
          res.data = appointment;
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    moveQueueAppointment({ commit, getters }, payload) {
      let event = { ...payload.event };
      delete event.id;
      return axios
        .put(
          `/api/appointment/move/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          event
        )
        .then(res => {
          const appointment = getters.getAppointmentFormat(res.data);
          commit("updateQueueAppointment", appointment);
          res.data = appointment;
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteQueueAppointment({ commit }, payload) {
      return axios
        .delete(
          `/api/appointment/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          commit("deleteQueueAppointment", payload.event.id);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    confirmQueueCheckIn({ commit, getters }, payload) {
      return axios
        .put(
          `/api/appointment/checkIn/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`,
          payload.event
        )
        .then(res => {
          const appointment = getters.getAppointmentFormat(res.data);
          commit("updateQueueAppointment", appointment);
          res.data = appointment;
          return res;
        })
        .catch(err => {
          const statusCode = err.response?.data.errorCode || null;
          const errorMessage = err.response?.data.errorMessage || null;
          if (statusCode === 400 && errorMessage === "No data found")
            eventBus.$emit("alertSwal", {
              title:
                "ไม่สามารถส่งตรวจได้ เนื่องจากไม่พบข้อมูลการเข้ารับบริการใน KTB",
              message: `อาจเกิดจาก เลขบัตรประชาชนไม่ตรง หรือยังไม่ได้กดรับบริการใน KTB`,
              icon: "warning"
            });
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    cancelQueueCheckIn({ commit, getters }, payload) {
      return axios
        .put(
          `/api/appointment/cancelCheckIn/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
        )
        .then(res => {
          const appointment = getters.getAppointmentFormat(res.data);
          commit("updateQueueAppointment", appointment);
          res.data = appointment;
          return res;
        })
        .catch(err => {
          if (err.response.data.errorMessage === "APPT-HAS-TREAT") {
            throw err;
          }
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    confirmationQueueAppointment({ commit, getters }, payload) {
      return axios
        .put(
          `/api/appointment/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`,
          payload.event
        )
        .then(res => {
          const appointment = getters.getAppointmentFormat(res.data);
          commit("updateQueueAppointment", appointment);
          res.data = appointment;
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    waitingForPaymentAppointment({ commit, getters }, payload) {
      return axios
        .put(
          `/api/appointment/status/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`,
          { statusId: 6 }
        )
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    cancelWaitingForPaymentAppointment({ commit, getters }, payload) {
      return axios
        .put(
          `/api/appointment/status/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`,
          { statusId: 5 }
        )
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    // ไม่ได้ใช้ appointment payment
    // paymentAppointment({ commit, getters }, payload) {
    //   return axios
    //     .post(
    //       `/api/appointment/payment/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
    //     )
    //     .catch(err => {
    //       eventBus.$emit("apiError", err);
    //       throw err;
    //     });
    // },

    referAppointment({ commit, getters }, payload) {
      return (
        axios
          .put(
            `/api/appointment/refer/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
            payload.event
          )
          // .put(
          //   `/api/appointment/refer/${payload.clinicUrl}/${payload.branchUrl}`,
          //   payload.event
          // )
          .then(res => {
            // const appointment = getters.getAppointmentFormat(res.data);
            // commit("updateQueueAppointment", appointment);
            // res.data = appointment;
            return res;
          })
          .catch(err => {
            eventBus.$emit("apiError", err);
            throw err;
          })
      );
    },

    //--------- Favorite ------------//
    updateTreatmentFavorite({ commit, getters }, payload) {
      return axios
        .post(
          // `/api/master/apptTreatFav/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
          `/api/master/apptTreat/fav/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          commit("setFavoriteTreatmentList", payload.event);
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteTreatmentFavorite({ commit, getters }, payload) {
      return axios
        .delete(
          `/api/master/apptTreat/fav/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
          // `/api/master/apptTreatFav/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          commit("setFavoriteTreatmentList", payload.event);
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    updateTreatDiagFevorite({ commit, getters }, payload) {
      return axios
        .post(
          `/api/master/treatDiag/fav/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          commit("setFevoriteTreatDiagList", payload.event);
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteTreatDiagFevorite({ commit, getters }, payload) {
      return axios
        .delete(
          `/api/master/treatDiag/fav/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          commit("setFevoriteTreatDiagList", payload.event);
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    updateTreatFevorite({ commit, getters }, payload) {
      return axios
        .post(
          `/api/master/treat/fav/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          commit("setFevoriteTreatList", payload.event);
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteTreatFevorite({ commit, getters }, payload) {
      return axios
        .delete(
          `/api/master/treat/fav/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          commit("setFevoriteTreatList", payload.event);
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    updateItemFevorite({ commit, getters }, payload) {
      return axios
        .post(
          `/api/master/item/fav/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          commit("setFevoriteItemList", payload.event);
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteItemFevorite({ commit, getters }, payload) {
      return axios
        .delete(
          `/api/master/item/fav/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          commit("setFevoriteItemList", payload.event);
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    // ------- treatment --------//
    // createTreatment({ commit, getters }, payload) {
    //   return axios
    //     .post(
    //       `/api/treatment/${payload.clinicUrl}/${payload.branchUrl}`,
    //       payload.event
    //     )
    //     .then(res => {
    //       return res;
    //     })
    //     .catch(err => {
    //       if (err.response.data === "TREAT-EXISTED") {
    //         throw err;
    //       }
    //       eventBus.$emit("apiError", err);
    //       throw err;
    //     });
    // },

    // list master //
    createTreatmentList({ commit, getters }, payload) {
      return axios
        .post(
          `/api/master/apptTreat/${payload.clinicUrl}/${payload.branchUrl}`,
          // `/api/master/apptTreat/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          commit("createTreatmentList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateTreatmentList({ commit, getters }, payload) {
      return axios
        .put(
          `/api/master/apptTreat/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          // `/api/master/apptTreat/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          payload.event
        )
        .then(res => {
          commit("updateTreatmentList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteTreatmentList({ commit, getters }, payload) {
      return axios
        .delete(
          `/api/master/apptTreat/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          // `/api/master/apptTreat/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          payload.event
        )
        .then(res => {
          commit("deleteTreatmentList", payload.event);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    createAppointmentCate({ commit, state }, payload) {
      return axios
        .post(
          `/api/master/apptTreatCate/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateAppointmentCate({ commit }, payload) {
      return axios
        .put(
          `/api/master/apptTreatCate/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteAppointmentCate({ commit }, payload) {
      return axios
        .delete(
          `/api/master/apptTreatCate/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    createTreatmentCate({ commit, state }, payload) {
      return axios
        .post(
          `/api/master/treatCate/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateTreatmentCate({ commit }, payload) {
      return axios
        .put(
          `/api/master/treatCate/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteTreatmentCate({ commit }, payload) {
      return axios
        .delete(
          `/api/master/treatCate/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    createDiagnosisCate({ commit, state }, payload) {
      return axios
        .post(
          `/api/master/treatDiagCate/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateDiagnosisCate({ commit }, payload) {
      return axios
        .put(
          `/api/master/treatDiagCate/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteDiagnosisCate({ commit }, payload) {
      return axios
        .delete(
          `/api/master/treatDiagCate/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    createItemCate({ commit, state }, payload) {
      return axios
        .post(
          `/api/master/itemCate/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateItemCate({ commit }, payload) {
      return axios
        .put(
          `/api/master/itemCate/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          payload.event
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteItemCate({ commit }, payload) {
      return axios
        .delete(
          `/api/master/itemCate/${payload.clinicUrl}/${payload.branchUrl}/${payload.id}`
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    findTreatListById({ commit, getters }, payload) {
      return axios
        .get(
          `/api/master/treat/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    createTreatList({ commit, getters }, payload) {
      return axios
        .post(
          `/api/master/treat/${payload.clinicUrl}/${payload.branchUrl}`,
          // `/api/master/treat/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          commit("createTreatList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateTreatList({ commit, getters }, payload) {
      return axios
        .put(
          `/api/master/treat/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          // `/api/master/treat/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          payload.event
        )
        .then(res => {
          commit("updateTreatList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteTreatList({ commit, getters }, payload) {
      return axios
        .delete(
          `/api/master/treat/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          // `/api/master/treat/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          payload.event
        )
        .then(res => {
          commit("deleteTreatList", payload.event);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    createTreatDiagList({ commit, getters }, payload) {
      return axios
        .post(
          `/api/master/treatDiag/${payload.clinicUrl}/${payload.branchUrl}`,
          // `/api/master/treatDiag/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          commit("createTreatDiagList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateTreatDiagList({ commit, getters }, payload) {
      return axios
        .put(
          `/api/master/treatDiag/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          // `/api/master/treatDiag/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          payload.event
        )
        .then(res => {
          commit("updateTreatDiagList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteTreatDiagList({ commit, getters }, payload) {
      return axios
        .delete(
          `/api/master/treatDiag/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          // `/api/master/treatDiag/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          payload.event
        )
        .then(res => {
          commit("deleteTreatDiagList", payload.event);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    findItemListById({ commit, getters }, payload) {
      return axios
        .get(
          `/api/master/item/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
          // `/api/master/item/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    createItemList({ commit, getters }, payload) {
      return axios
        .post(
          `/api/master/item/${payload.clinicUrl}/${payload.branchUrl}`,
          // `/api/master/item/${payload.clinicUrl}/${payload.branchUrl}`,
          payload.event
        )
        .then(res => {
          commit("createItemList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    updateItemList({ commit, getters }, payload) {
      return axios
        .put(
          `/api/master/item/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          // `/api/master/item/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          payload.event
        )
        .then(res => {
          commit("updateItemList", res.data);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    deleteItemList({ commit, getters }, payload) {
      return axios
        .delete(
          `/api/master/item/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          // `/api/master/item/${payload.clinicUrl}/${payload.branchUrl}/${payload.event.id}`,
          payload.event
        )
        .then(res => {
          commit("deleteItemList", payload.event);
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    //--------- Queue State ---------//
    setStateQueueDate({ commit }, date) {
      commit("setQueueDate", date);
    },
    setStateQueueAppointmentList({ commit }, data) {
      commit("setQueueAppointmentList", data);
    },
    createStateQueueAppointment({ commit, getters }, data) {
      const appointment = getters.getAppointmentFormat(data);
      commit("createQueueAppointment", appointment);
    },
    updateStateQueueAppointment({ commit, getters }, data) {
      const appointment = getters.getAppointmentFormat(data);
      commit("updateQueueAppointment", appointment);
    },
    updateStateQueueAppointmentPatient({ commit, getters }, data) {
      let appointment = getters.getQueueAppointmentBy(data.id, "id");
      if (appointment && !(Object.keys(appointment).length === 0)) {
        appointment.title = getters.getAppointmentTitle(
          {
            patient: data.patient,
            patientId: data.patient.id,
            aptTreatItems: appointment.extendedProps.treatmentList,
            doctorName: appointment.extendedProps.doctorName
          },
          "\n"
        );
        appointment.extendedProps.dn = data.patient.dn;
        appointment.extendedProps.patientName = `${data.patient.titleNameTh ||
          ""} ${data.patient.firstNameTh} ${data.patient.lastNameTh}`;
        appointment.extendedProps.phoneNo = `${
          data.patient.mobile
            ? getters.getMobileNumFormat(data.patient.mobile)
            : ""
        } ${
          data.patient.tel
            ? `\n ${getters.getTelNumFormat(data.patient.tel)}`
            : ""
        }`.trim();
        appointment.extendedProps.patient = data.patient;
        commit("updateQueueAppointment", appointment);
      }
    },
    deleteStateQueueAppointment({ commit }, id) {
      commit("deleteQueueAppointment", id);
    },
    createStateWaitingForPaymentApptList({ commit, getters }, data) {
      const appointment = getters.getAppointmentFormat(data);
      commit("createWaitingForPaymentApptList", appointment);
    },
    deleteStateWaitingForPaymentApptList({ commit }, id) {
      commit("deleteWaitingForPaymentApptList", id);
    },

    //--------- Doctor API ---------//
    fetchDoctorAppointmentList({ commit, getters }, payload) {
      if (!payload.store && payload.store !== false) {
        payload.store = true;
      }
      return axios(
        `/api/appointment/${payload.clinicUrl}/${payload.branchUrl}`,
        {
          params: {
            startDt: moment(payload.date)
              .startOf("day")
              .format(),
            endDt: moment(payload.date)
              .endOf("day")
              .format(),
            doctorUid: payload.uid
          }
        }
      ).then(res => {
        const appointmentList = res.data.appointments.map(event => {
          return getters.getAppointmentFormat(event, "doctor");
        });
        if (payload.store) {
          commit("setDoctorAppointmentList", appointmentList);

          let checkinList = appointmentList.filter(
            i => i.extendedProps.statusId === 5
          );
          commit("setDoctorCheckInList", checkinList);
        }
        res.data = appointmentList;
        return res;
      });
    },

    fetchDoctorCheckInList({ commit, getters }, payload) {
      if (!payload.store && payload.store !== false) {
        payload.store = true;
      }
      return axios(
        `/api/appointment/${payload.clinicUrl}/${payload.branchUrl}`,
        {
          params: {
            startDt: moment(payload.date)
              .startOf("day")
              .format(),
            endDt: moment(payload.date)
              .endOf("day")
              .format(),
            doctorUid: payload.doctorUid,
            statusId: 5 //Check-in
          }
        }
        // `/api/appointment/findByDate/${payload.clinicUrl}/${payload.branchUrl}/${payload.date}`
      ).then(res => {
        const appointmentList = res.data.appointments.map(event => {
          return getters.getAppointmentFormat(event, "doctor");
        });
        if (payload.store) {
          commit("setDoctorCheckInList", appointmentList);
        }
        res.data = appointmentList;
        return res;
      });
    },

    //--- All doctor API ---//
    setStateAllDoctorsDate({ commit }, date) {
      commit("setAllDoctorsDate", date);
    },
    createStateAllDoctorsAppointment({ commit, getters }, data) {
      const appointment = getters.getAppointmentFormat(data, "doctor");
      commit("createAllDoctorsAppointment", appointment);
    },
    updateStateAllDoctorsAppointment({ commit, getters }, data) {
      const appointment = getters.getAppointmentFormat(data, "doctor");
      commit("updateAllDoctorsAppointment", appointment);
    },
    deleteStateAllDoctorsAppointment({ commit }, id) {
      commit("deleteAllDoctorsAppointment", id);
    },
    createStateAllDoctorsCheckInList({ commit, getters }, data) {
      const appointment = getters.getAppointmentFormat(data, "doctor");
      commit("createAllDoctorsCheckInList", appointment);
    },
    updateStateAllDoctorsCheckInList({ commit, getters }, data) {
      const appointment = getters.getAppointmentFormat(data, "doctor");
      commit("updateAllDoctorsCheckInList", appointment);
    },
    deleteStateAllDoctorsCheckInList({ commit }, id) {
      commit("deleteAllDoctorsCheckInList", id);
    },

    fetchAllDoctorsAppointment({ commit, getters }, payload) {
      if (!payload.store && payload.store !== false) {
        payload.store = true;
      }
      return axios
        .get(`/api/appointment/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: {
            startDt: moment(payload.date)
              .startOf("day")
              .format(),
            endDt: moment(payload.date)
              .endOf("day")
              .format()
          }
        })
        .then(res => {
          const appointmentList = res.data.appointments.map(event => {
            return getters.getAppointmentFormat(event);
          });
          if (payload.store) {
            commit("setAllDoctorsAppointment", appointmentList);
          }

          const checkInList = res.data.appointments.map(apt => {
            return getters.getAppointmentFormat(apt, "doctor");
          });
          if (payload.store) {
            commit("setAllCheckInList", checkInList);
          }

          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },
    fetchAllCheckInList({ commit, getters }, payload) {
      if (!payload.store && payload.store !== false) {
        payload.store = true;
      }
      return axios
        .get(
          `/api/appointment/doctor/${payload.clinicUrl}/${payload.branchUrl}`,
          {
            params: {
              startDt: moment(payload.date)
                .startOf("day")
                .format(),
              endDt: moment(payload.date)
                .endOf("day")
                .format()
            }
          }
        )
        .then(res => {
          const checkInList = res.data.map(checkIn => {
            checkIn.appointments = checkIn.appointments.appointments
              .filter(appointment => appointment.statusId === 5)
              .map(event => {
                return getters.getAppointmentFormat(event, "doctor");
              });
            return checkIn;
          });
          if (payload.store) {
            commit("setAllCheckInList", checkInList);
          }
          res.data = checkInList;
          return res;
        })
        .catch(err => {
          console.error(err);
        });
    },
    fetchNextVisitAppointmentByPatientId({ commit, getters }, payload) {
      return axios
        .get(`/api/appointment/${payload.clinicUrl}/${payload.branchUrl}`, {
          params: {
            patientId: payload.patientId,
            limit: 1,
            startDt: moment()
              // .utc()
              .add(1, "days")
              .startOf("day")
              .format()
          }
        })
        .then(res => {
          return getters.getNextVisitFormat(res.data?.appointments[0]);
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    //--------- Doctor State ---------//
    setStateDoctorDate({ commit }, date) {
      commit("setDoctorDate", date);
    },
    setStateDoctorAppointmentList({ commit }, data) {
      commit("setDoctorAppointmentList", data);
    },
    createStateDoctorAppointment({ commit, getters }, data) {
      const appointment = getters.getAppointmentFormat(data, "doctor");
      commit("createDoctorAppointment", appointment);
    },
    updateStateDoctorAppointment({ commit, getters }, data) {
      const appointment = getters.getAppointmentFormat(data, "doctor");
      commit("updateDoctorAppointment", appointment);
    },
    deleteStateDoctorAppointment({ commit }, id) {
      commit("deleteDoctorAppointment", id);
    },
    createStateDoctorCheckInList({ commit, getters }, data) {
      const appointment = getters.getAppointmentFormat(data, "doctor");
      commit("createDoctorCheckInList", appointment);
    },
    updateStateDoctorCheckInList({ commit, getters }, data) {
      const appointment = getters.getAppointmentFormat(data, "doctor");
      commit("updateDoctorCheckInList", appointment);
    },
    deleteStateDoctorCheckInList({ commit }, id) {
      commit("deleteDoctorCheckInList", id);
    }
  },
  getters: {
    //--------- Utils ---------//
    findBy: () => (dataList, value, key, defaultVal) => {
      if (!value) {
        return defaultVal;
      }
      return (
        dataList.find(function(item) {
          return item[key] == value;
        }) || defaultVal
      );
    },
    getNextVisitFormat: (state, getters, rootState, rootGetters) => data => {
      if (isEmpty(data) || isNaN(parseInt(data.id)) || isEmpty(data.startDt)) {
        return null;
      } else {
        const doctorData = rootGetters[
          "moduleUser/getUserListIncludeDeletedBy"
        ](data.doctorUid, "uid");
        const treatmentList = getters.getTreatmentDataList(
          get(data, "aptTreatItems", [])
        );
        return {
          start: get(data, "startDt"),
          doctorName: doctorData?.fullName || "",
          doctorNameEn: doctorData?.fullNameEn || "",
          treatmentList: treatmentList,
          treatmentTitle: isEmpty(treatmentList)
            ? ""
            : treatmentList.length > 1
            ? `${get(treatmentList, "0.nameTh")} และอื่นๆ`
            : get(treatmentList, "0.nameTh"),
          treatmentTitleEn: isEmpty(treatmentList)
            ? ""
            : treatmentList.length > 1
            ? `${get(treatmentList, "0.nameEn")} and more`
            : get(treatmentList, "0.nameEn")
        };
      }
    },
    getAppointmentFormat: (state, getters, rootState, rootGetters) => (
      data,
      type = null
    ) => {
      const doctorData = rootGetters["moduleUser/getUserListIncludeDeletedBy"](
        data.doctorUid,
        "uid"
      );
      const treatmentTitle = getters.getTreatmentData(data, "\n", "•")
        .treatmentTitle;
      const timeDuration = getters.getTimeDuration(data, "minutes");
      function adjust(color, amount) {
        return (
          "#" +
          color
            .replace(/^#/, "")
            .replace(/../g, color =>
              (
                "0" +
                Math.min(
                  255,
                  Math.max(0, parseInt(color, 16) + amount)
                ).toString(16)
              ).substr(-2)
            )
        );
      }
      let event = {
        id: data.id,
        start: data.startDt,
        end: data.endDt,
        resourceId: data.roomId,
        color: "transparent",
        title: getters.getAppointmentTitle(data, "\n"),
        startEditable: type != "doctor" ? !data.checkinDt : false,
        resourceEditable: type != "doctor" ? !data.checkinDt : false,
        extendedProps: {
          id: data.id,
          timeDuration: timeDuration ? timeDuration : 0,
          roomId: data.roomId,
          doctor: data.doctorUid,
          doctorColor: doctorData?.drApptColor || "",
          doctorColorBorder: doctorData?.drApptColor
            ? adjust(doctorData.drApptColor, -45)
            : "",
          doctorName: doctorData?.fullName || "",
          statusColor: data.statusColor ? data.statusColor : "",
          statusId: data.statusId ? data.statusId : "",
          treatmentList: data.aptTreatItems,
          treatmentTitle: treatmentTitle,
          note: data.note,
          checkin: {
            checkinDt: data.checkinDt,
            checkinStatus: data.checkinStatus,
            checkinStatusName: data.checkinStatusName,
            checkinNote: data.checkinNote
          },
          dn: data.patient?.dn,
          existDn: data.patient?.existDn,
          patientName: data.patient
            ? `${data.patient.titleNameTh || ""} ${data.patient.firstNameTh} ${
                data.patient.lastNameTh
              }`
            : null,
          phoneNo: `${
            data.patient?.mobile
              ? getters.getMobileNumFormat(data.patient.mobile)
              : ""
          } ${
            data.patient?.tel
              ? `\n ${getters.getTelNumFormat(data.patient.tel)}`
              : ""
          }`.trim(),
          treatmentPeriod: `${moment(data.startDt).format("HH:mm")} - ${moment(
            data.endDt
          ).format("HH:mm")}`,
          patient: data.patient,
          treatmentDt: data.treatmentDt,
          rmdId: data.rmdId,
          linkedWithKtbPaotang: data.linkedWithKtbPaotang,
          linkedWithMFD: data.linkedWithMFD,
          mfdAppointmentMeetingId: data.mfdAppointmentMeetingId,
          mfdAppointmentMeetingUrl: data.mfdAppointmentMeetingUrl,
          mfdClipboradText: data.linkedWithMFD
            ? getters.getMFDClipboradText(data)
            : "",
          nhsoVisitBookingNo: data.nhsoVisitBookingNo,
          nhsoVisitNo: data.nhsoVisitNo,
          creationUid: data.creationUid,
          creationDt: data.creationDt,
          modifyUid: data.modifyUid,
          modifyDt: data.modifyDt
        }
      };
      return event;
    },
    getTimeDuration: () => (data, time = "seconds") => {
      const startTime = moment(data.startDt);
      const endTime = moment(data.endDt);
      const totalTime = endTime.diff(startTime, time);
      return totalTime;
    },
    getTreatmentDataList: (state, getters) => dataList => {
      return dataList?.map((id, index) => {
        const dataTreatment = getters.getTreatmentBy(id, "id");
        return { id: id, nameTh: "", nameEn: "", ...dataTreatment };
      });
    },
    getTreatmentData: (state, getters) => (
      data,
      delimiter = ",",
      bullet = "•"
    ) => {
      let treatmentTitle = "";
      let timeDuration = 0;
      data.aptTreatItems?.forEach((id, index) => {
        const dataTreatment = getters.getTreatmentBy(id, "id");
        treatmentTitle += `${bullet} ${get(dataTreatment, "nameTh", "")} ${
          index < data.aptTreatItems?.length - 1 ? delimiter : ""
        }`;
        timeDuration += dataTreatment.timeUse;
      });
      return { treatmentTitle, timeDuration };
    },
    getAppointmentTitle: (state, getters, rootState, rootGetters) => (
      data,
      delimiter = ","
    ) => {
      const doctorData = rootGetters["moduleUser/getUserListIncludeDeletedBy"](
        data.doctorUid,
        "uid"
      );
      const patient = data.patient;
      let title = "";
      if (data.patientId == null) {
        if (data.note) {
          title += `${data.note}${delimiter}`;
        }
        if (doctorData?.fullName) {
          title += `ทพ.: ${doctorData?.fullName}${delimiter}`;
        }
        return title;
      }
      if (data.patientId === 1) {
        title += `คนไข้ใหม่ ไม่มีประวัติ${delimiter}`;
        data.aptTreatItems?.forEach(id => {
          title += `- ${get(
            getters.getTreatmentBy(id, "id"),
            "nameTh",
            ""
          )}${delimiter}`;
        });
        title += `ทพ.: ${doctorData?.fullName || data.doctorName}${delimiter}`;
        if (data.note) {
          title += `${data.note}${delimiter}`;
        }
        return title;
      }
      function getAge(dateString) {
        if (!dateString) return "";
        const date = moment(dateString, "YYYY-MM-DD");
        return `(${moment().diff(date, "years")})`;
      }

      let age = getAge(patient?.dob);
      title += `${patient?.firstNameTh} ${patient?.lastNameTh} ${age}${delimiter}`;
      title += `DN: ${patient?.dn}${delimiter}`;
      data.aptTreatItems?.forEach(id => {
        title += `- ${get(
          getters.getTreatmentBy(id, "id"),
          "nameTh",
          ""
        )}${delimiter}`;
      });
      title += `ทพ.: ${doctorData?.fullName || data.doctorName}${delimiter}`;
      if (data.note) {
        title += `${data.note}${delimiter}`;
      }
      return title;
    },
    getMFDClipboradText: (state, getters, rootState, rootGetters) => (
      data,
      delimiter = "\n"
    ) => {
      const doctorData = rootGetters["moduleUser/getUserListIncludeDeletedBy"](
        data.doctorUid,
        "uid"
      );
      const patient = data.patient;
      let text = "";
      text += `เรียนคุณ ${patient?.firstNameTh} ${patient?.lastNameTh}${delimiter}`;
      text += `วันที่ ${moment(data.startDt)
        .add(543, "year")
        .locale("th")
        .format("LL")}${delimiter}`;
      text += `เวลา ${moment(data.startDt).format("HH:mm")} - ${moment(
        data.endDt
      ).format("HH:mm")} ${delimiter}`;
      text += `มีนัดกับปรึกษากับทันตแพทย์ ${doctorData?.fullName ||
        data.doctorName} `;
      if (data.aptTreatItems?.length > 0) {
        text += "เพื่อ";
        data.aptTreatItems?.forEach(id => {
          text += `- ${get(getters.getTreatmentBy(id, "id"), "nameTh", "")} `;
        });
      }
      text += delimiter;
      text += delimiter;
      text += `สามารถ click link นี้เพื่อเข้าสู่การ Teledentistry ด้วย MorFundee${delimiter}`;
      text += `${data.mfdAppointmentMeetingUrl}${delimiter}`;
      return text;
    },
    getMobileNumFormat: (state, getters) => data => {
      return data.replace(/(\d{1,3})(\d{1,3})?(\d{1,10})?/g, "$1-$2-$3");
    },
    getTelNumFormat: (state, getters) => data => {
      let isBkk = data.slice(0, 2) == "02";
      if (isBkk) {
        return data.replace(/(\d{1,2})(\d{1,3})?(\d{1,10})?/g, "$1-$2-$3");
      } else {
        return data.replace(/(\d{1,3})(\d{1,3})?(\d{1,10})?/g, "$1-$2-$3");
      }
    },
    getDoctorData: () => data => {
      return {
        color: get(data, "drApptColor", "#000"),
        name: get(data, "user.fullName", ""),
        nameEn: get(data, "user.fullNameEn", "")
      };
    },
    getGroupedTxList: (state, getters) => {
      //favorite group
      const favGroup = [
        {
          category: "รายการใช้ประจำ",
          libs: []
        }
      ];
      getters.getTreatmentList
        .filter(item => item.favorite == true)
        .forEach(item => {
          favGroup[0].libs.push(item);
        });
      // grouped by category
      const grouped = Object.values(
        getters.getTreatmentList.reduce((result, item) => {
          // Create new group
          if (!result[item.cateId]) {
            result[item.cateId] = {
              category: get(
                getters.getTreatmentCateBy(item.cateId, "id"),
                "nameTh",
                ""
              ),
              libs: []
            };
          }
          // Append to group
          result[item.cateId].libs.push(item);
          return result;
        }, {})
      );
      return favGroup.concat(grouped);
    },
    getGroupedItemList: (state, getters) => {
      //favorite group
      const favGroup = [
        {
          category: "รายการใช้ประจำ",
          libs: []
        }
      ];
      getters.getItemList
        .filter(item => item.favorite == true)
        .forEach(item => {
          favGroup[0].libs.push(item);
        });
      // grouped by category
      const grouped = Object.values(
        getters.getItemList.reduce((result, item) => {
          // Create new group
          if (!result[item.cateId]) {
            result[item.cateId] = {
              category: get(
                getters.getItemCateBy(item.cateId, "id"),
                "nameTh",
                ""
              ),
              libs: []
            };
          }
          // Append to group
          result[item.cateId].libs.push(item);
          return result;
        }, {})
      );
      return favGroup.concat(grouped);
    },
    getGroupedTreatDiagList: (state, getters) => {
      const list = getters.getTreatDiagList.filter(i => i.branchId !== 0);

      //favorite group
      const favGroup = [
        {
          category: "รายการใช้ประจำ",
          libs: []
        }
      ];
      list
        .filter(item => item.favorite == true)
        .forEach(item => {
          favGroup[0].libs.push(item);
        });
      // grouped by category
      const grouped = Object.values(
        list.reduce((result, item) => {
          // Create new group
          if (!result[item.cateId]) {
            result[item.cateId] = {
              category: get(
                getters.getTreatDiagCateBy(item.cateId, "id"),
                "nameTh",
                ""
              ),
              libs: []
            };
          }
          // Append to group
          result[item.cateId].libs.push(item);
          return result;
        }, {})
      );
      return favGroup.concat(grouped);
    },
    getGroupedTreatDiagListNhso: (state, getters) => {
      const list = getters.getTreatDiagList.filter(i => i.branchId === 0);
      //favorite group
      const favGroup = [
        {
          category: "รายการใช้ประจำ",
          libs: []
        }
      ];
      list
        .filter(item => item.favorite == true)
        .forEach(item => {
          favGroup[0].libs.push(item);
        });
      // grouped by category
      const grouped = Object.values(
        list.reduce((result, item) => {
          // Create new group
          if (!result[item.cateId]) {
            result[item.cateId] = {
              category: get(
                getters.getTreatDiagCateBy(item.cateId, "id"),
                "nameTh",
                ""
              ),
              libs: []
            };
          }
          // Append to group
          result[item.cateId].libs.push(item);
          return result;
        }, {})
      );
      return favGroup.concat(grouped);
    },
    getGroupedTreatDiagListPlan: (state, getters) => {
      const list = getters.getTreatDiagList;
      //favorite group
      const favGroup = [
        {
          category: "รายการใช้ประจำ",
          libs: []
        }
      ];
      list
        .filter(item => item.favorite == true)
        .forEach(item => {
          favGroup[0].libs.push(item);
        });
      // grouped by category
      const grouped = Object.values(
        list.reduce((result, item) => {
          // Create new group
          if (!result[item.cateId]) {
            result[item.cateId] = {
              category: get(
                getters.getTreatDiagCateBy(item.cateId, "id"),
                "nameTh",
                ""
              ),
              libs: []
            };
          }
          // Append to group
          result[item.cateId].libs.push(item);
          return result;
        }, {})
      );
      return favGroup.concat(grouped);
    },
    getGroupedTreatList: (state, getters) => {
      const list = getters.getTreatList
        .filter(i => i.branchId !== 0)
        .sort((a, b) => {
          // Handle empty/null values (move to end)
          if (!a.code && !b.code) return 0;
          if (!a.code) return 1;
          if (!b.code) return -1;

          // Convert both codes to uppercase for case-insensitive comparison
          const codeA = a.code.toUpperCase();
          const codeB = b.code.toUpperCase();

          // Use localeCompare with numeric sorting
          return codeA.localeCompare(codeB, undefined, {
            numeric: true,
            sensitivity: "base" // Case-insensitive comparison
          });
        });
      //favorite group
      const favGroup = [
        {
          category: "รายการใช้ประจำ",
          libs: []
        }
      ];
      list
        .filter(item => item.favorite == true)
        .forEach(item => {
          favGroup[0].libs.push(item);
        });
      // grouped by category
      const grouped = Object.values(
        list.reduce((result, item) => {
          // Create new group
          if (!result[item.cateId]) {
            result[item.cateId] = {
              category: get(
                getters.getTreatCateBy(item.cateId, "id"),
                "nameTh",
                ""
              ),
              libs: []
            };
          }
          // Append to group
          result[item.cateId].libs.push(item);
          return result;
        }, {})
      );
      return favGroup.concat(grouped);
    },
    getGroupedTreatListNhso: (state, getters) => {
      const list = getters.getTreatList
        .filter(i => i.branchId === 0)
        .sort((a, b) => {
          // Handle empty/null values (move to end)
          if (!a.code && !b.code) return 0;
          if (!a.code) return 1;
          if (!b.code) return -1;

          // Convert both codes to uppercase for case-insensitive comparison
          const codeA = a.code.toUpperCase();
          const codeB = b.code.toUpperCase();

          // Use localeCompare with numeric sorting
          return codeA.localeCompare(codeB, undefined, {
            numeric: true,
            sensitivity: "base" // Case-insensitive comparison
          });
        });

      //favorite group
      const favGroup = [
        {
          category: "รายการใช้ประจำ",
          libs: []
        }
      ];
      list
        .filter(item => item.favorite == true)
        .forEach(item => {
          favGroup[0].libs.push(item);
        });
      // grouped by category
      const grouped = Object.values(
        list.reduce((result, item) => {
          // Create new group
          if (!result[item.cateId]) {
            result[item.cateId] = {
              category: get(
                getters.getTreatCateBy(item.cateId, "id"),
                "nameTh",
                ""
              ),
              libs: []
            };
          }
          // Append to group
          result[item.cateId].libs.push(item);
          return result;
        }, {})
      );
      return favGroup.concat(grouped);
    },
    getNhsoMtreatIds: (state, getters) => {
      const list = getters.getTreatList.filter(i => i.branchId === 0);
      return list.map(i => i.id);
    },
    getGroupedTreatListPlan: (state, getters) => {
      const list = getters.getTreatList;
      //favorite group
      const favGroup = [
        {
          category: "รายการใช้ประจำ",
          libs: []
        }
      ];
      list
        .filter(item => item.favorite == true)
        .forEach(item => {
          favGroup[0].libs.push(item);
        });
      // grouped by category
      const grouped = Object.values(
        list.reduce((result, item) => {
          // Create new group
          if (!result[item.cateId]) {
            result[item.cateId] = {
              category: get(
                getters.getTreatCateBy(item.cateId, "id"),
                "nameTh",
                ""
              ),
              libs: []
            };
          }
          // Append to group
          result[item.cateId].libs.push(item);
          return result;
        }, {})
      );
      return favGroup.concat(grouped);
    },
    getGroupTxListNoFav: (state, getters) => {
      // grouped by category
      const grouped = Object.values(
        getters.getTreatList.reduce((result, item) => {
          // Create new group
          if (!result[item.cateId]) {
            result[item.cateId] = {
              category: get(
                getters.getTreatCateBy(item.cateId, "id"),
                "nameTh",
                ""
              ),
              libs: []
            };
          }
          // Append to group
          result[item.cateId].libs.push(item);
          return result;
        }, {})
      );
      return grouped;
    },
    getGroupedItemListNoFav: (state, getters) => {
      // grouped by category
      return Object.values(
        getters.getItemList.reduce((result, item) => {
          // Create new group
          if (!result[item.cateId]) {
            result[item.cateId] = {
              category: get(
                getters.getItemCateBy(item.cateId, "id"),
                "nameTh",
                ""
              ),
              libs: []
            };
          }
          // Append to group
          result[item.cateId].libs.push(item);
          return result;
        }, {})
      );
    },

    getAptHxTableList: (state, getters, rootState, rootGetters) => {
      return getters.getAppointmentHistoryList.map(apt => {
        return {
          id: apt.id,
          date: apt.startDt,
          doctor: rootGetters["moduleUser/getUserListIncludeDeletedBy"](
            apt.doctorUid,
            "uid"
          ).fullName,
          title: apt.aptTreatItems
            ?.map(item => get(getters.getTreatmentBy(item, "id"), "nameTh", ""))
            .toString(),
          checkinStatus: apt.checkinStatus,
          checkinStatusName: (function() {
            if (apt.checkinStatus == 2 || apt.checkinStatus == 3) {
              let start = moment(apt.startDt)
                .format("HH:mm")
                .split(":");
              let end = moment(apt.checkinDt)
                .format("HH:mm")
                .split(":");
              let minuteStart = start[0] * 60 + start[1] * 1;
              let minuteEnd = end[0] * 60 + end[1] * 1;
              return (
                apt.checkinStatusName +
                " ( " +
                Math.abs(minuteStart - minuteEnd) +
                " นาที )"
              );
            } else if (apt.checkinStatus) {
              return apt.checkinStatusName;
            } else {
              if (
                moment(apt.startDt).isBefore(moment(), "day") &&
                ![5, 6, 7].includes(apt.statusId)
              )
                return "ไม่มา";
            }
          })(),
          aptStatus: rootGetters["moduleMaster/getAppointmentStatusBy"](
            apt.statusId,
            "val"
          ).label,
          note: apt.note
        };
      });
    },

    //--------- Master ---------//
    getTreatmentList: state => {
      return state.treatmentList;
    },
    getTreatmentBy: (state, getters) => (value, key) => {
      return getters.findBy(state.treatmentList, value, key, {}) || {};
    },
    getTreatmentCateList: state => {
      return state.treatmentCateList;
    },
    getTreatmentCateBy: (state, getters) => (value, key) => {
      return getters.findBy(state.treatmentCateList, value, key, {}) || {};
    },
    getTreatDiagList: state => {
      return state.treatDiagList;
    },
    getTreatDiagBy: (state, getters) => (value, key) => {
      return getters.findBy(state.treatDiagList, value, key, {}) || {};
    },
    getTreatDiagCateList: state => {
      return state.treatDiagCateList;
    },
    getTreatDiagCateBy: (state, getters) => (value, key) => {
      return getters.findBy(state.treatDiagCateList, value, key, {}) || {};
    },
    getTreatList: state => {
      return state.treatList;
    },
    getTreatBy: (state, getters) => (value, key) => {
      return getters.findBy(state.treatList, value, key, {}) || {};
    },
    getTreatCateList: state => {
      return state.treatCateList;
    },
    getTreatCateBy: (state, getters) => (value, key) => {
      return getters.findBy(state.treatCateList, value, key, {}) || {};
    },
    getItemList: state => {
      return state.itemList;
    },
    getItemBy: (state, getters) => (value, key) => {
      return getters.findBy(state.itemList, value, key, {}) || {};
    },
    getItemCateList: state => {
      return state.itemCateList;
    },
    getItemCateBy: (state, getters) => (value, key) => {
      return getters.findBy(state.itemCateList, value, key, {}) || {};
    },
    //--------- Queue ---------//
    getQueueAppointmentList: state => {
      return state.queueAppointmentList;
    },
    getTomorrowAppointmentList: state => {
      return state.tomorrowAppointmentList;
    },
    getQueueAppointmentBy: (state, getters) => (value, key) => {
      return getters.findBy(state.queueAppointmentList, value, key, null) || {};
    },
    getDoctorsAppointmentBy: (state, getters) => (value, key) => {
      return (
        getters.findBy(state.allDoctorsAppointment, value, key, null) || {}
      );
    },
    getWaitingForPaymentApptList: state => {
      return state.waitingForPaymentApptList;
    },
    getPoatangApptList: state => {
      return state.poatangApptList;
    },
    getAppointmentHistoryList: state => {
      return state.appointmentHistoryList;
    },
    getAppointmentHistoryBy: (state, getters) => (value, key) => {
      return (
        getters.findBy(state.appointmentHistoryList, value, key, null) || {}
      );
    },
    //--------- Doctor ---------//
    getDoctorAppointmentList: state => {
      return state.doctorAppointmentList;
    },
    getDoctorAppointmentBy: (state, getters) => (value, key) => {
      return (
        getters.findBy(state.doctorAppointmentList, value, key, null) || {}
      );
    },
    getDoctorCheckInList: state => {
      return state.doctorCheckInList;
    },

    //-----All doctors-----//
    getAllDoctorsAppointment: state => {
      return state.allDoctorsAppointment;
    },
    getAllCheckInList: state => {
      return state.allCheckInList;
    },
    getNhsoBookingNo: state => {
      return state.appointment?.extendedProps?.nhsoVisitBookingNo;
    }
  }
};
export default moduleAppointment;

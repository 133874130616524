import axios from "axios";

const moduleAuth = {
  namespaced: true,
  state: {
    loginApiStatus: "",
    userProfile: {
      uid: 0,
      firstNameTh: "",
      lastNameTh: "",
      email: "",
      mobile: ""
    },
    logOut: false,
    accessToken: null
  },
  mutations: {
    setLoginApiStatus(state, data) {
      state.loginApiStatus = data;
    },
    setUserProfile(state, data) {
      const userProfile = {
        id: data.id,
        lastName: data.lastName,
        firstName: data.firstName,
        email: data.email,
        phone: data.phone
      };
      state.userProfile = userProfile;
    },
    setLogout(state, payload) {
      state.logOut = payload;
    },
    setAccessToken(state, payload) {
      state.accessToken = payload;
    }
  },
  actions: {
    loginApi({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/login`, payload)
          .then(res => {
            if (res && res.data) {
              localStorage.setItem("access_token", res.data.data.accessToken);
              commit("setUserProfile", res.data.data.user);
              commit("setLoginApiStatus", "success");
            }

            resolve(res);
          })
          .catch(err => {
            commit("setLoginApiStatus", "failed");
            reject(err);
          });
      });
    },
    async userLogout({ commit }) {
      const response = await axios
        .post(`/api/logout`)
        .catch(err => {
          console.error(err);
        });

      if (response && response.data) {
        commit("setLogout", true);
        localStorage.removeItem("access_token");
      } else {
        commit("setLogout", false);
      }
    },
    refreshToken({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/token`)
          .then(res => {
            const { status } = res;
            if (status == 200 || status == 204) {
              localStorage.setItem("access_token", res.data.data.accessToken);
              commit("setAccessToken", res.data.data.accessToken);
            }
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    checkUserLogin(payload) {
      return new Promise((resolve, reject) => {
        const endPoint =
          payload && payload.clinicUrl && payload.branchUrl
            ? `/api/${payload.clinicUrl}/${payload.branchUrl}`
            : `/api/user/checkLogin`;

        axios
          .get(endPoint)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },
  getters: {
    getLoginApiStatus(state) {
      return state.loginApiStatus;
    },
    getUserProfile(state) {
      return state.userProfile;
    },
    getLogout(state) {
      return state.logOut;
    },
    getAccessToken(state) {
      return state.accessToken;
    }
  }
};

export default moduleAuth;

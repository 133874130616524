<template>
  <div>
    <!-- showing message while loading images -->
    <div v-show="loading" class="loading-overlay">
      <p>Loading Images, please wait...</p>
    </div>

    <!-- <div class="stage-container"> -->
    <div>
      <canvas ref="pdfCanvas" style="display: none"></canvas>
      <div
        v-for="(pageConfig, index) in pdfImageConfig"
        :key="'stage-container-' + index"
        class="stage-container"
      >
        <!-- <canvas ref="pdfCanvas"  style="display: none"></canvas> -->
        <!-- <canvas
          v-for="(page, index) in pdfImageConfig"
          :ref="'pdfCanvas' + index"
          :key="'canvas-' + index"
          style="display:none"
        ></canvas> -->
        <div class="page-wrapper">
          <!-- :config="configKonva" -->
          <v-stage
            :ref="'stage' + index"
            :config="getPageConfig(index)"
            @mousedown="startDrawing($event, index)"
            @touchstart="checkPen($event, index)"
            @mouseup="stopDrawing"
            @touchend="stopDrawing"
            @mousemove="drawing($event, index)"
            @touchmove="drawing($event, index)"
          >
            <v-layer>
              <!-- <v-image :config="pdfImageConfig"></v-image>-->
              <!-- v-for="(pageConfig, index) in pdfImageConfig" -->
              <v-image :key="'image-' + index" :config="pageConfig"></v-image>
              <!-- <v-line
              v-for="(line, index) in lines"
              :key="`page-${index}`"
              :config="line"
            ></v-line> -->
              <v-line
                v-for="(line, lineindex) in lines[index]"
                :key="`page-${index}-line-${lineindex}`"
                :config="line"
              ></v-line>
            </v-layer>
          </v-stage>
        </div>
      </div>
    </div>
    <span @click="closeTab" class="close-button" aria-label="Close">
      &times;
    </span>
    <div class="sticky-buttons">
      <b-button class="mt-2" variant="outline-danger" @click="resetDrawing"
        ><i class="fas fa-trash"></i
      ></b-button>
      <b-button class="mt-2" variant="outline-primary" @click="exportToPDF"
        ><i class="fas fa-upload"></i
      ></b-button>
      <b-button
        class="mt-2"
        variant="outline-success"
        @click="exportToPDF({ isPrint: true })"
        ><i class="fas fa-print"></i
      ></b-button>
    </div>

    <!-- Uploading message overlay -->
    <div v-if="isUploading" class="uploading-message">Uploading...</div>
    <Dialog ref="Dialog"></Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import Vue from "vue";
import VueKonva from "vue-konva";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
// import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Dialog from "@/components/modal/Dialog";

import { eventBus } from "@/main";
Vue.use(VueKonva);

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
  name: "PdfKonva",
  components: { Dialog },
  data() {
    return {
      pdfUrl: null, // Path to your PDF file
      docId: null,
      patientId: null,
      clinicUrl: null,
      branchUrl: null,
      filename: null,
      // pdfImageConfig: {
      //   image: null,
      //   x: 0,
      //   y: 0,
      //   width: 0,
      //   height: 0,
      // },
      pdfImageConfig: [],
      loading: false,
      isUploading: false,
      configKonva: {
        width: 800, // Set the stage width
        height: 600, // Set the stage height
      },
      lines: [],
      isDrawing: false,
      currentLine: [],
      isPen: false,
    };
  },
  mounted() {
    eventBus.$on("renderPDF", this.renderPDF);
    window.addEventListener("pointerdown", e => {
      this.isPen = e.pointerType === "pen";
    });
    const query = this.$route.query;

    if (this.$route.query) {
      this.pdfUrl = this.$route.query.src;
      this.docId = parseInt(this.$route.query.id);
      this.patientId = parseInt(this.$route.query.patientId);
      this.clinicUrl = this.$route.query.clinicUrl;
      this.branchUrl = this.$route.query.branchUrl;
      this.filename = this.$route.query.filename;
    }

    this.renderPDF(this.pdfUrl);
  },
  methods: {
    ...mapActions({
      createMediaFiles: "moduleMediaFile/createMediaFiles",
    }),
    getPageConfig(index) {
      // Return the configuration for the specific stage (index)
      // Adjust width, height, or other properties as needed
      return {
        width: this.configKonva.width, // Assuming you already have a configKonva object
        height: this.configKonva.height, // You might want to adjust these dynamically
      };
    },

    async renderPDF(pdfUrl) {
      this.loading = true; // Show loading spinner at the start
      try {
        const loadingTask = pdfjsLib.getDocument(pdfUrl);
        const pdf = await loadingTask.promise;
        const numPages = pdf.numPages;

        let accumulatedHeight = 0;
        this.pdfImageConfig = [];

        await this.$nextTick();
        console.log("this.$refs: ", this.$refs);

        for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
          const page = await pdf.getPage(pageNumber);
          console.log(`Rendering page ${pageNumber}...`);

          const scale = 4.0;
          const viewport = page.getViewport({ scale });
          const canvas = this.$refs.pdfCanvas;
          const context = canvas.getContext("2d");

          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          await page.render(renderContext).promise;
          console.log(`Page ${pageNumber} rendered`);

          const image = new window.Image();
          image.src = canvas.toDataURL("image/png");

          await new Promise(resolve => {
            image.onload = () => {
              const aspectRatio = canvas.width / canvas.height;
              const stageWidth = this.configKonva.width;
              const stageHeight = stageWidth / aspectRatio;

              this.configKonva.width = stageWidth;
              this.configKonva.height = stageHeight;

              const pageConfig = {
                image: image,
                x: 0,
                y: 0,
                width: stageWidth,
                height: stageHeight,
              };

              this.pdfImageConfig.push(pageConfig);
              console.log("this.pdfConfig", this.pdfImageConfig);
              resolve();
            };
          });
        }

        console.log("All pages loaded and rendered successfully.");
      } catch (error) {
        console.error("Error rendering PDF: ", error);
      } finally {
        this.loading = false; // Hide loading spinner after rendering
      }
    },

    checkPen(e, pageIndex) {
      if (!this.isPen) return;
      this.startDrawing(e, pageIndex);
    },
    startDrawing(e, pageIndex) {
      this.isDrawing = true;
      const stage = e.target.getStage();
      const pos = stage.getPointerPosition();
      this.currentLine = {
        points: [pos.x, pos.y],
        stroke: "black",
        strokeWidth: 2,
        lineCap: "round",
        lineJoin: "round",
        tension: 0.5,
      };

      if (!this.lines[pageIndex]) {
        this.$set(this.lines, pageIndex, []);
      }
      // this.lines.push(this.currentLine);
      this.lines[pageIndex].push(this.currentLine);
    },
    drawing(e, pageIndex) {
      if (!this.isDrawing) return;
      const stage = e.target.getStage();
      const pos = stage.getPointerPosition();

      const newPoints = this.currentLine.points.concat([pos.x, pos.y]);
      this.currentLine.points = newPoints;

      // Ensure that the updated line is correctly reflected in the lines array for this page
      this.$set(
        this.lines[pageIndex],
        this.lines[pageIndex].length - 1,
        this.currentLine
      );
    },
    stopDrawing() {
      this.isDrawing = false;
      let points = this.chunkArrayInGroups(this.currentLine.points, 2); //grouping array
      let simplifiedPoints = this.simplifyLineRDP(points, 1); //ลดจำนวน points ทำให้ เส้น smooth มากขึ้น ตัวเลข คือระยะหว่างจุดที่จะนำมาคำนวน
      this.currentLine.points = [].concat.apply([], simplifiedPoints); //flatten array
    },
    resetDrawing() {
      this.$refs.Dialog.showAlertConfirm(
        "ลบลายเซ็นต์ทั้งหมด ?",
        "คุณต้องการลบลายเซ็นต์ทั้งหมด",
        null,
        "ยืนยัน",
        "ยกเลิก"
      ).then(result => {
        if (result.value) this.lines = [];
      });
    },
    chunkArrayInGroups(arr, size) {
      var result = [];
      for (var i = 0; i < arr.length; i += size)
        result.push(arr.slice(i, i + size));
      return result;
    },
    simplifyLineRDP(points, length) {
      var simplify = function (start, end) {
        // recursize simplifies points from start to end
        var maxDist, index, xx, yy, dx, dy, ddx, ddy, p1, p2, p, t, dist, dist1;
        p1 = points[start];
        p2 = points[end];
        xx = p1[0];
        yy = p1[1];
        ddx = p2[0] - xx;
        ddy = p2[1] - yy;
        dist1 = ddx * ddx + ddy * ddy;
        maxDist = length;
        for (var i = start + 1; i < end; i++) {
          p = points[i];
          if (ddx !== 0 || ddy !== 0) {
            t = ((p[0] - xx) * ddx + (p[1] - yy) * ddy) / dist1;
            if (t > 1) {
              dx = p[0] - p2[0];
              dy = p[1] - p2[1];
            } else if (t > 0) {
              dx = p[0] - (xx + ddx * t);
              dy = p[1] - (yy + ddy * t);
            } else {
              dx = p[0] - xx;
              dy = p[1] - yy;
            }
          } else {
            dx = p[0] - xx;
            dy = p[1] - yy;
          }
          dist = dx * dx + dy * dy;
          if (dist > maxDist) {
            index = i;
            maxDist = dist;
          }
        }

        if (maxDist > length) {
          // continue simplification while maxDist > length
          if (index - start > 1) {
            simplify(start, index);
          }
          newLine.push(points[index]);
          if (end - index > 1) {
            simplify(index, end);
          }
        }
      };
      var end = points.length - 1;
      var newLine = [points[0]];
      simplify(0, end);
      newLine.push(points[end]);
      return newLine;
    },

    // exportToPDF1({ isPrint, isDownload }) {
    //   const pixelRatio = 4.0; // Increase the pixelRatio for higher resolution
    //   let pdf = null; // Initialize the PDF object

    //   console.log("upload click");

    //   // Loop over each page/stage using `pdfImageConfig`
    //   for (let index = 0; index < this.pdfImageConfig.length; index++) {
    //     const stageRefKey = `stage${index}`; // Dynamically generate the key

    //     // Check if the stage ref exists and has multiple instances
    //     const stageRefs = this.$refs[stageRefKey];

    //     if (stageRefs && stageRefs.length > 0) {
    //       // Handle each stage (if multiple instances exist)
    //       stageRefs.forEach((stageComponent, stageIndex) => {
    //         const stage = stageComponent.getStage(); // Get the actual Konva stage
    //         const dataURL = stage.toDataURL({ pixelRatio });

    //         // Create an off-screen canvas to compress the image
    //         const offScreenCanvas = document.createElement("canvas");
    //         const offScreenContext = offScreenCanvas.getContext("2d");
    //         offScreenCanvas.width = stage.width() * pixelRatio;
    //         offScreenCanvas.height = stage.height() * pixelRatio;

    //         const img = new Image();
    //         img.src = dataURL;

    //         img.onload = async () => {
    //           offScreenContext.drawImage(
    //             img,
    //             0,
    //             0,
    //             offScreenCanvas.width,
    //             offScreenCanvas.height
    //           );

    //           const compressedDataURL = offScreenCanvas.toDataURL(
    //             "image/jpeg",
    //             0.7
    //           );
    //           const pdfWidth = stage.width();
    //           const pdfHeight = stage.height();

    //           if (pdf === null) {
    //             pdf = new jsPDF({
    //               orientation: "portrait",
    //               unit: "pt",
    //               format: [pdfWidth, pdfHeight],
    //             });
    //           }

    //           if (index === 0 && stageIndex === 0) {
    //             pdf.addImage(
    //               compressedDataURL,
    //               "JPEG",
    //               0,
    //               0,
    //               pdfWidth,
    //               pdfHeight
    //             );
    //           } else {
    //             pdf.addPage([pdfWidth, pdfHeight]);
    //             pdf.addImage(
    //               compressedDataURL,
    //               "JPEG",
    //               0,
    //               0,
    //               pdfWidth,
    //               pdfHeight
    //             );
    //           }

    //           // If this is the last page and the last stage, finish the PDF generation
    //           if (
    //             index === this.pdfImageConfig.length - 1 &&
    //             stageIndex === stageRefs.length - 1
    //           ) {
    //             const blob = pdf.output("blob");
    //             if (isPrint) {
    //               const pdfDataUrl = pdf.output("dataurlstring");
    //               // Handle printing
    //               const overlay = document.createElement("div");
    //               overlay.style.position = "fixed";
    //               overlay.style.top = "0";
    //               overlay.style.left = "0";
    //               overlay.style.width = "100%";
    //               overlay.style.height = "100%";
    //               overlay.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
    //               overlay.style.zIndex = "1000";
    //               overlay.style.display = "flex";
    //               overlay.style.justifyContent = "center";
    //               overlay.style.alignItems = "center";
    //               overlay.style.flexDirection = "column";

    //               // Create a close button
    //               const closeButton = document.createElement("button");
    //               closeButton.innerText = "X";
    //               closeButton.style.position = "absolute";
    //               closeButton.style.top = "10px";
    //               closeButton.style.right = "10px";
    //               closeButton.style.padding = "10px 20px";
    //               closeButton.style.fontSize = "16px";
    //               closeButton.style.backgroundColor = "#fff";
    //               closeButton.style.border = "none";
    //               closeButton.style.borderRadius = "50%";
    //               closeButton.style.cursor = "pointer";
    //               closeButton.onclick = () => {
    //                 document.body.removeChild(overlay);
    //               };

    //               // Create an iframe to display the PDF
    //               const pdfIframe = document.createElement("iframe");
    //               pdfIframe.src = pdfDataUrl;
    //               pdfIframe.style.width = "80%";
    //               pdfIframe.style.height = "80%";
    //               pdfIframe.style.border = "none";

    //               // Append elements to the overlay
    //               overlay.appendChild(closeButton);
    //               overlay.appendChild(pdfIframe);

    //               // Append overlay to the body
    //               document.body.appendChild(overlay);
    //             } else if (isDownload) {
    //               pdf.save("document.pdf");
    //             } else {
    //               await this.handleCreateMediaFile(blob);

    //               this.$refs.Dialog.showAlertInfo(
    //                 "Upload ไฟล์สำเร็จ",
    //                 null,
    //                 "success"
    //               );
    //               localStorage.setItem(
    //                 "crossTabEvent",
    //                 JSON.stringify({
    //                   type: "POLL_FOR_UPDATE",
    //                   payload: { patientId: this.patientId, files: [blob] },
    //                 })
    //               );
    //             }
    //           }
    //         };
    //       });
    //     } else {
    //       console.error(`Stage ${index} is undefined or invalid`);
    //     }
    //   }
    // },

    async exportToPDF({ isPrint, isDownload }) {
      this.isUploading = true;
      try {
        const pixelRatio = 3.0; // Higher resolution for better quality
        const compressionQuality = 0.7;
        let pdf = null;

        console.log("Upload started");

        // Process all pages in parallel
        const imagePromises = this.pdfImageConfig.map(async (config, index) => {
          const stageRefKey = `stage${index}`;
          const stageComponent = this.$refs[stageRefKey][0]?.getStage();

          if (!stageComponent) {
            console.error(`Stage ${index} is undefined or invalid`);
            return null;
          }

          const dataURL = stageComponent.toDataURL({ pixelRatio });
          const offScreenCanvas = document.createElement("canvas");
          const offScreenContext = offScreenCanvas.getContext("2d");
          offScreenCanvas.width = stageComponent.width() * pixelRatio;
          offScreenCanvas.height = stageComponent.height() * pixelRatio;

          return new Promise(resolve => {
            const img = new Image();
            img.src = dataURL;
            img.onload = () => {
              offScreenContext.drawImage(
                img,
                0,
                0,
                offScreenCanvas.width,
                offScreenCanvas.height
              );

              const compressedDataURL = offScreenCanvas.toDataURL(
                "image/jpeg",
                compressionQuality
              );
              resolve({
                compressedDataURL,
                width: stageComponent.width(),
                height: stageComponent.height(),
              });
            };
          });
        });

        const imageDataArray = await Promise.all(imagePromises);

        // Initialize PDF once with the first page dimensions
        const { width: pdfWidth, height: pdfHeight } = imageDataArray[0];
        pdf = new jsPDF({
          orientation: "portrait",
          unit: "pt",
          format: [pdfWidth, pdfHeight],
        });

        // Add each image to the PDF
        imageDataArray.forEach((imageData, index) => {
          if (!imageData) return; // Skip if invalid

          const { compressedDataURL, width, height } = imageData;
          if (index > 0) pdf.addPage([width, height]);

          pdf.addImage(compressedDataURL, "JPEG", 0, 0, width, height);
        });

        // Finalizing PDF generation
        const blob = pdf.output("blob");

        // Handle printing or downloading
        if (isPrint) {
          const pdfDataUrl = pdf.output("dataurlstring");
          // Handle printing
          const overlay = document.createElement("div");
          overlay.style.position = "fixed";
          overlay.style.top = "0";
          overlay.style.left = "0";
          overlay.style.width = "100%";
          overlay.style.height = "100%";
          overlay.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
          overlay.style.zIndex = "1000";
          overlay.style.display = "flex";
          overlay.style.justifyContent = "center";
          overlay.style.alignItems = "center";
          overlay.style.flexDirection = "column";

          // Create a close button
          const closeButton = document.createElement("button");
          closeButton.innerText = "X";
          closeButton.style.position = "absolute";
          closeButton.style.top = "10px";
          closeButton.style.right = "10px";
          closeButton.style.padding = "10px 20px";
          closeButton.style.fontSize = "16px";
          closeButton.style.backgroundColor = "#fff";
          closeButton.style.border = "none";
          closeButton.style.borderRadius = "50%";
          closeButton.style.cursor = "pointer";
          closeButton.onclick = () => {
            document.body.removeChild(overlay);
          };

          // Create an iframe to display the PDF
          const pdfIframe = document.createElement("iframe");
          pdfIframe.src = pdfDataUrl;
          pdfIframe.style.width = "80%";
          pdfIframe.style.height = "80%";
          pdfIframe.style.border = "none";

          // Append elements to the overlay
          overlay.appendChild(closeButton);
          overlay.appendChild(pdfIframe);

          // Append overlay to the body
          document.body.appendChild(overlay);
        } else if (isDownload) {
          pdf.save("document.pdf");
        } else {
          await this.handleCreateMediaFile(blob);

          localStorage.setItem(
            "crossTabEvent",
            JSON.stringify({
              type: "POLL_FOR_UPDATE",
              payload: { patientId: this.patientId, files: [blob] },
            })
          );
        }
      } catch (error) {
        console.error("Error during exportToPDF", error);
      } finally {
        this.isUploading = false;
      }
    },

    async handleCreateMediaFile(pdfBlob) {
      try {
        eventBus.$emit("appLoadingStatus", true);
        await this.createMediaFiles({
          clinicUrl: this.clinicUrl,
          branchUrl: this.branchUrl,
          data: {
            category: "Document",
            patientId: this.patientId ? String(this.patientId) : null,
            remark: this.filename
              ? `${this.filename}`
              : `Signed_Document_${this.$utils.generateDateTimeString()}`,
            filesMetadata: [{ id: null, contentType: "application/pdf" }],
          },
          files: [pdfBlob],
        });

        localStorage.removeItem("crossTabEvent");

        // Small delay to ensure removal is processed
        localStorage.removeItem("crossTabEvent");

        // Small delay to ensure removal is processed
        setTimeout(() => {
          // Set new value to trigger storage event
          localStorage.setItem(
            "crossTabEvent",
            JSON.stringify({
              timestamp: new Date().getTime(), // Add timestamp to ensure value change
              type: "POLL_FOR_UPDATE",
              payload: {
                patientId: this.patientId,
                files: [pdfBlob],
                source: "signDoc",
              },
            })
          );
        }, 100);
        this.$refs.Dialog.showAlertInfo("Upload ไฟล์สำเร็จ", null, "success");
      } catch (error) {
        console.error(error);
      } finally {
        eventBus.$emit("appLoadingStatus", false);
      }
    },
    closeTab() {
      this.$refs.Dialog.showAlertConfirm(
        "ปิดหน้าจอนี้?",
        "คุณต้องการปิดหน้าจอนี้",
        null,
        "ยืนยัน",
        "ยกเลิก"
      ).then(result => {
        if (result.value) window.close();
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("pointerdown", e => {
      this.isPen = e.pointerType === "pen";
    });
    if (this.pdfUrl) {
      URL.revokeObjectURL(this.pdfUrl);
    }
  },
};
</script>

<style>
.stage-container {
  border: 2px solid #333;
  border-radius: 10px;
  margin: 1rem;
  display: inline-block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.sticky-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
}
.close-button {
  font-size: 1.5rem;
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  cursor: pointer;
}
.page-wrapper {
  width: auto; /* Or any fixed width you want for each page */
  padding: 10px; /* Add some spacing between pages */
  border: 1px solid #ccc;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: #333;
  z-index: 1000;
}
.uploading-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 1rem 2rem;
  border-radius: 5px;
  font-size: 1.2rem;
  z-index: 1000;
}
</style>
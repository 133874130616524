// store/aiAnalysis.js

import axios from "axios";
import { eventBus } from "@/main";

export default {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    fetchIncomeAnalysis({ commit }, payload) {
      return axios
        .get(
          `/api/report/analysisItem/${payload.clinicUrl}/${payload.branchUrl}`,
          {
            params: {
              startDt: payload.startDt,
              endDt: payload.endDt
            }
          }
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    },

    // New recommendations API call
    fetchIncomeRecommendations({ commit }, payload) {
      return axios
        .post(
          `/api/report/analysisItem/${payload.clinicUrl}/${payload.branchUrl}/recommendations`,
          {
            analyticsData: payload.analyticsData
          },
          {
            params: {
              startDt: payload.startDt,
              endDt: payload.endDt
            }
          }
        )
        .then(res => {
          return res;
        })
        .catch(err => {
          eventBus.$emit("apiError", err);
          throw err;
        });
    }
  },
  getters: {}
};

import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";

import Home from "@/views/Home.vue";
import Login from "@/views/user/Login.vue";
import OAuth2 from "@/views/user/OAuth2.vue";
import SignDoc from "@/views/SignDoc.vue";
import Logout from "@/views/user/Logout.vue";
import Register from "@/views/user/Register.vue";
import UserLayout from "@/components/layouts/UserLayout.vue";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import moduleUser from "@/stores/user";
import store from "@/store";

import { eventBus } from "@/main";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "errors-404" */ "@/views/error/NotFound.vue")
  },
  {
    path: "/",
    name: "Home",
    redirect: "/clinic",
    component: Home
  },

  {
    path: "/login",
    name: "Login",
    meta: { title: "Login" },
    component: Login
  },
  {
    path: "/oauth2",
    name: "OAuth2",
    meta: { title: "OAuth2" },
    component: OAuth2
  },
  {
    path: "/document",
    name: "Document",
    meta: { title: "Document" },
    component: SignDoc
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/user",
    component: UserLayout,
    redirect: "/user/activate",
    children: [
      {
        path: "/user/activate/:activateKey?",
        name: "UserActivate",
        meta: { title: "User Activate" },
        component: () => import("@/views/user/Activate.vue")
      },
      {
        path: "/user/forgotpassword",
        name: "ForgotPassword",
        meta: { title: "Forgot Password" },
        component: () => import("@/views/user/ForgotPassword.vue")
      },
      {
        path: "/user/resetpassword/:resetPasswordKey",
        name: "ResetPassword",
        meta: { title: "Reset Password" },
        component: () => import("@/views/user/ResetPassword.vue")
      }
    ]
  },
  {
    path: "/clinic",
    component: DefaultLayout,
    children: [
      {
        path: "/clinic",
        name: "Clinic",
        meta: { title: "Clinic", requiresAuth: true },
        component: () => import("@/views/Clinic.vue")
      }
    ]
  },
  {
    path: "/receipt",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("receipt");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/receipt",
        name: "Receipt",
        meta: { title: "Receipt", requiresAuth: true, requiresDataFetch: true },
        component: () => import("@/views/Receipt.vue")
      }
    ]
  },
  {
    path: "/queuemanage",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("queuemanage");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/queuemanage",
        name: "QueueManage",
        meta: {
          title: "จัดการคิวการรักษา",
          requiresAuth: true,
          requiresDataFetch: true,
          keepAlive: true,
          accessRoles: ["ASSISTANT", "COUNTER", "MANAGER", "HEADCOUNTER"]
        },
        component: () => import("@/views/QueueManage.vue")
      }
    ]
  },
  {
    path: "/queuelist",
    component: DefaultLayout,
    redirect: to => {
      return redirectClinicBranch("queuelist");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/queuelist/:param?",
        name: "QueueList",
        meta: {
          title: "ราการนัดหมาย",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["ASSISTANT", "COUNTER", "MANAGER", "HEADCOUNTER"]
        },
        component: () => import("@/views/QueueList.vue"),
        props: true
      }
    ]
  },
  {
    path: "/usermanage",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("usermanage");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/usermanage",
        name: "UserManage",
        meta: {
          title: "จัดการผู้ใช้",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["MANAGER", "COUNTER", "HEADCOUNTER"]
        },
        component: () => import("@/views/UserManage.vue")
      }
    ]
  },
  {
    path: "/aptlistmanage",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("aptlistmanage");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/aptlistmanage",
        name: "AptListManage",
        meta: {
          title: "จัดการลิสต์นัดหมาย",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["COUNTER", "MANAGER", "DOCTOR", "HEADCOUNTER"]
        },
        component: () => import("@/views/AptListManage.vue")
      }
    ]
  },
  {
    path: "/txlistmanage",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("txlistmanage");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/txlistmanage",
        name: "TxListManage",
        meta: {
          title: "จัดการลิสต์การรักษา",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["COUNTER", "MANAGER", "DOCTOR", "HEADCOUNTER"]
        },
        component: () => import("@/views/TxListManage.vue")
      }
    ]
  },
  {
    path: "/dxlistmanage",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("dxlistmanage");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/dxlistmanage",
        name: "DxListManage",
        meta: {
          title: "จัดการลิสต์วินิจฉัย",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["COUNTER", "MANAGER", "DOCTOR", "HEADCOUNTER"]
        },
        component: () => import("@/views/DxListManage.vue")
      }
    ]
  },
  {
    path: "/itemslistmanage",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("itemslistmanage");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/itemslistmanage",
        name: "ItemsListManage",
        meta: {
          title: "ลิสต์ยา และสินค้า",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["COUNTER", "MANAGER", "DOCTOR", "HEADCOUNTER"]
        },
        component: () => import("@/views/ItemsListManage.vue")
      }
    ]
  },
  {
    path: "/labproductmanage",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("labproductmanage");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/labproductmanage",
        name: "LabProductManage",
        meta: {
          title: "รายการแลป",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["COUNTER", "MANAGER", "DOCTOR", "HEADCOUNTER"]
        },
        component: () => import("@/views/LabProductManage.vue")
      }
    ]
  },
  {
    path: "/doctor/dashboard",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("doctor/dashboard");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/doctor/dashboard",
        name: "Dashboard",
        meta: {
          title: "จัดการคิวการรักษา",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["DOCTOR"]
        },
        component: () => import("@/views/DoctorDashboard.vue")
      }
    ]
  },
  {
    path: "/doctorsTxQ",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("doctorsTxQ");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/doctorsTxQ",
        name: "DoctorsTreatingQueue",
        meta: {
          title: "คิวรอตรวจแพทย์ทุกท่าน",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["ASSISTANT"]
        },
        component: () => import("@/views/DoctorsTreatingQueue.vue")
      }
    ]
  },
  {
    path: "/packagemanage",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("packagemanage");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/packageManage",
        name: "PackageManage",
        meta: {
          title: "จัดการรายการนัดหมาย",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["COUNTER", "MANAGER", "DOCTOR", "HEADCOUNTER"]
        },
        component: () => import("@/views/PackageManage.vue")
      }
    ]
  },
  {
    path: "/receiptmanage",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("receiptmanage");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/receiptmanage",
        name: "ReceiptManage",
        meta: {
          title: "จัดการใบเสร็จ",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["COUNTER", "MANAGER", "HEADCOUNTER"]
        },
        component: () => import("@/views/ReceiptManage.vue")
      }
    ]
  },
  {
    path: "/labexpense",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("labexpense");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/labexpense",
        name: "LabExpense",
        meta: {
          title: "จัดการค่าแลป",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["COUNTER", "MANAGER", "HEADCOUNTER"]
        },
        component: () => import("@/views/LabExpense.vue")
      }
    ]
  },

  {
    path: "/report/personal",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("/report/personal");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/report/personal",
        name: "PersonalIncome",
        meta: {
          title: "รายได้แพทย์",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["COUNTER", "MANAGER", "DOCTOR", "HEADCOUNTER"]
        },
        component: () => import("@/views/PersonalIncomeReport.vue")
      }
    ]
  },
  {
    path: "/report/branch",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("/report/branch");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/report/branch",
        name: "BranchIncome",
        meta: {
          title: "รายได้สาขา",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["COUNTER", "MANAGER", "DOCTOR", "HEADCOUNTER"]
        },
        component: () => import("@/views/BranchIncomeReport.vue")
      }
    ]
  },
  {
    path: "/analysis/branch",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("/analysis/branch");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/analysis/branch",
        name: "ิbranchAnalysis",
        meta: {
          title: "วิเคราห์สาขา",
          requiresAuth: true,
          requiresDataFetch: true,
          keepAlive: true,
          accessRoles: ["MANAGER"]
        },
        component: () => import("@/views/BranchAnalysis.vue")
      }
    ]
  },
  {
    path: "/filemanage",
    component: DefaultLayout,

    redirect: () => {
      return redirectClinicBranch("filemanage");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/filemanage",
        name: "FileManage",
        meta: {
          title: "จัดการไฟล์",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["MANAGER", "HEADCOUNTER"]
        },
        component: () => import("@/views/FileManage.vue")
      }
    ]
  },
  {
    path: "/setting",
    component: DefaultLayout,
    redirect: () => {
      return redirectClinicBranch("setting");
    },
    children: [
      {
        path: "/:clinicUrl/:branchUrl/setting",
        name: "Setting",
        meta: {
          title: "Setting",
          requiresAuth: true,
          requiresDataFetch: true,
          accessRoles: ["COUNTER", "MANAGER", "HEADCOUNTER"]
        },
        component: () => import("@/views/Setting.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  routes
});

function redirectClinicBranch(to) {
  const branchPath = moduleUser.state.branchPath;
  if (branchPath) {
    return `/${branchPath}/${to}`;
  } else {
    return "/clinic";
  }
}

async function fetchData(to) {
  if (!to?.params) {
    throw new Error("Invalid route parameters");
  }

  try {
    eventBus.$emit("appLoadingStatus", true);

    // Critical sequential data that must be fetched in order
    await store.dispatch(
      "moduleAppointment/fetchTomorrowAppointmentList",
      to.params
    );
    await store.dispatch("moduleTreatment/fetchOrthoPackageList", to.params);

    // Non-critical data that can be fetched in parallel
    const parallelFetches = [
      {
        name: "Branch Members",
        action: "moduleUser/fetchAllBranchMembers"
      },
      {
        name: "Appointment Categories",
        action: "moduleAppointment/fetchAppointmentCateAndList"
      },
      {
        name: "Diagnosis Categories",
        action: "moduleAppointment/fetchDiagnosisCateAndList"
      },
      {
        name: "Treatment Categories",
        action: "moduleAppointment/fetchTreatmentCateAndList"
      },
      {
        name: "Item Categories",
        action: "moduleAppointment/fetchItemCateAndList"
      }
    ];

    await Promise.allSettled(
      parallelFetches.map(({ action }) =>
        store.dispatch(action, to.params).catch(error => {
          console.error(`Failed to fetch ${action}:`, error);
          // Re-throw to maintain original behavior
          throw error;
        })
      )
    );
  } catch (error) {
    console.error("Data fetching failed:", error);
    throw error;
  } finally {
    eventBus.$emit("appLoadingStatus", false);
  }
}

let dataFetched = false;
router.beforeEach(async (to, from, next) => {
  let publicPageNames = [
    "Login",
    "ForgotPassword",
    "ResetPassword",
    "OAuth2",
    "edcweb"
  ];
  if (publicPageNames.includes(to.name)) {
    NProgress.start();
    next();
    return;
  }

  if (!moduleUser.state.userInfo) {
    await store.dispatch("moduleUser/fetchUserLogin", to.params);
  }

  if (to.name === "Clinic") dataFetched = false; //Fetced new data when branch changed

  if (to.meta.requiresAuth == true) {
    await store
      .dispatch("moduleUser/newCheckUserLogin", to.params)
      .then(async _res => {
        const isAccess = moduleUser.getters.getIsAccessMenu(moduleUser.state)(
          to.meta.accessRoles
        );
        if (
          to.meta.accessRoles &&
          to.meta.accessRoles.length > 0 &&
          !isAccess
        ) {
          next({ path: "/clinic" });
        } else {
          NProgress.start();
          if (to.meta.requiresDataFetch && !dataFetched) {
            await fetchData(to);
            dataFetched = true;
          }
          next();
        }
      })
      .catch(_err => {
        next({ path: "/clinic" });
      });
  } else {
    NProgress.start();
    next();
  }
});

router.afterEach(async (to, from) => {
  NProgress.done();
});

export default router;

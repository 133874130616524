import moduleAppointment from "./appointment";
import moduleBranch from "./branch";
import moduleMaster from "./master";
import moduleUser from "./user";
import modulePatient from "./patient";
import moduleContent from "./content";
import moduleTreatment from "./treatment";
import moduleReceipt from "./receipt";
import moduleReport from "./report";
import moduleLab from "./lab";
import moduleAuth from "./auth";
import moduleCariesRiskAssessment from "./cariesRiskAssessment";
import moduleTreatmentPlan from "./treatmentPlan";
import moduleMediaFile from "./mediaFile";
import moduleTTD from "./TTD";
import moduleFhir from "./FHIR";
import moduleAiAnalysis from "./aiAnalysis";

export default {
  moduleAppointment,
  moduleBranch,
  moduleMaster,
  moduleUser,
  modulePatient,
  moduleContent,
  moduleTreatment,
  moduleReceipt,
  moduleReport,
  moduleLab,
  moduleAuth,
  moduleCariesRiskAssessment,
  moduleTreatmentPlan,
  moduleMediaFile,
  moduleTTD,
  moduleFhir,
  moduleAiAnalysis
};
